import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/public/layout';
import Newspanel from '../components/public/newsPanel'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet } from 'react-helmet';

export const query = graphql`
    query($slug: String!) {
        contentfulOgloszeniaIIntencje(linkName: {eq: $slug}){
            title
            publishDate(formatString: "D MMMM YYYY", locale: "pl")
            author
            content {
                raw
            }
            linkName
        }
    }
`

const Annoucement = (props) => {
    const options = {
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
    }
    return (
        <Layout>
            <Helmet title={`${props.data.contentfulOgloszeniaIIntencje.title} | Parafia w Starych Skoszewach`}/>
            <div className="container">
                <div className="left-col">
                    <div className="article-without-photo">
                        <span className='where'>
                            <Link to='/'>Strona Główna</Link>
                            <span className='where-arrow'>&gt;</span>
                            <Link to='/aktualnosci'>Aktualności</Link>
                            <span className='where-arrow'>&gt;</span>
                            <Link to='/ogloszenia-i-intencje'>Ogłoszenia i Intencje</Link>
                        </span>
                        <h3>{props.data.contentfulOgloszeniaIIntencje.title}</h3>
                        <div className="line">
                            <span>{props.data.contentfulOgloszeniaIIntencje.publishDate}</span>
                            <span>Autor: {props.data.contentfulOgloszeniaIIntencje.author}</span>
                        </div>
                        <div style={{ color: '#111111', textAlign: 'justify' }}>
                            {documentToReactComponents(JSON.parse(props.data.contentfulOgloszeniaIIntencje.content.raw), options)}
                        </div>
                    </div>
                </div>
                <div className="right-col">
                    <Newspanel currArticle={props.data.contentfulOgloszeniaIIntencje.linkName}/>
                    {/* <div className="facebook-panel"></div> */}
                </div>
            </div>
        </Layout>
    );
}

export default Annoucement

