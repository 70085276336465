import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Link } from 'gatsby';
const Newspanel = ({currArticle}) => {

    const data = useStaticQuery(graphql`
    query {
        allContentfulOgloszeniaIIntencje{
            edges {
                node {
                    title
                    publishDate
                    linkName
                }
            }
        }
        allContentfulArtykulyBiezace{
            edges {
                node {
                    title
                    publishDate
                    linkName
                }
            }
        }
    }
    
    `)
    var full = data.allContentfulOgloszeniaIIntencje.edges.concat(data.allContentfulArtykulyBiezace.edges)
    full.sort((x, y) => {
        let a = new Date(x.node.publishDate)
        let b = new Date(y.node.publishDate);
        return b - a;
    })
    return (
        <div className="announcements">
            <h2 style={{ lineHeight: '80px' }}>Najnowsze</h2>
            {
                full.filter(el => el.node.linkName !== currArticle).slice(0, 5).map((edge, i) => {
                    return (
                        <Link to={edge.node.linkName.startsWith('ogloszenia')
                            ? `/ogloszenia-i-intencje/${edge.node.linkName}`
                            : edge.node.linkName.startsWith('intencje')
                                ? `/ogloszenia-i-intencje/${edge.node.linkName}`
                                : `/artykuly/${edge.node.linkName}`}
                                key={`news-single-${i}`}
                        >
                            <div className="single" >
                                <span className='date'>{(new Date(edge.node.publishDate)).toLocaleDateString('pl', { year: 'numeric', month: 'long', day: 'numeric' })}</span><br />
                                <span className="title">{edge.node.title}</span>
                            </div>
                        </Link>
                    )
                })
            }
            <span className='others'><Link to='/aktualnosci'>STARSZE</Link></span>
        </div>
    );
}

export default Newspanel;
